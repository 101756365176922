<template>
    <div id="aboutme">

        <div class="pages-header">
            <el-image :src='require("../../assets/actbg@3x.png")' fit="cover">
            </el-image>
        </div>

        <div class="aboutme-content">
            <div class="content-title">
                <p><i class="el-icon-s-home"></i> 关于我们</p>
            </div>
            <div class="content-warp">
                    <!-- 成都美伦堡教育科技有限公司（简称“美伦堡教育”），是一家以“教育安全引领科技，科技服务于教育安全”为理念，致力于创建科学、全面的教育安全体系的科技公司。公司以探究校园安全现状、完善安全管理系统为基础，不断创新与研究，构建校园安全大数据平台，为教育行政部门、社区、学校、家庭等客户提供教育安全综合性的解决方案。美伦堡教育力争当好学校帮手、政府助手及行家里手，立足成都，服务全省，幅射西南，遍布全国，着力打造最具责任感、最专业、最权威的教育安全科技公司。成都美伦堡教育科技有限公司的愿景是“将科技创新融入教育安全的方方面面”，推进构建完整的社会、学校、家庭为一体的教育安全体系。 -->
                <p style="text-align: justify; text-indent: 2em;">
                    成都心安理得安全科技研究院成立于2019年，是由国内知名安全专家、教育专家共同发起成立的安全机构，专注于校园安全、社区安全、校园安全信息化建设、
                    校园安全舆情防控等研究，拥有一支团结合作、充满激情的团队，共有国家级安全专家1名，省级安全专家2名，市级安全专家5名，教育专家5名。
                </p>
                <p style="text-align: justify; text-indent: 2em;">
                    经过两年多发展，目前服务遍布成都市武侯区、高新区、温江区、天府新区、成华区、青羊区、双流区、简阳市等区域。
                </p>
                 <p style="text-align: justify; text-indent: 2em;">
                    成都心安理得安全科技研究院和四川师范大学公共安全与应急研究院达成战略合作关系，是四川省应急管理学会校园安全专业委员会成员单位。我们将以国家政策为导向，以社会责任为己任，团结务实、高效诚信，开创安全行业新篇章。
                </p>
                <!-- <p>    
                    联系人：邓先生
                    联系电话：02885036656/17711056900</p> -->
                <p style="text-align: justify; text-indent: 2em;">
                    联系人：邓先生</p>
                <p style="text-align: justify; text-indent: 2em;">
                    联系电话：02885036656 / 17711056900</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "aboutme",
        components: {},
        created() {
        },
        mounted() {
        },
        data() {
            return {};
        },
        methods: {}
    }
</script>

<style scoped lang="less">
    #aboutme {
        .pages-header {
            width: 100%;
            font-size: 14px;
            color: #666666;
            line-height: 36px;

            .el-image {
                display: block;
                width: 100%;
                /*height: 200px;*/
            }
        }

        .aboutme-content {
            width: 100%;

            .content-title {
                width: 100%;
                height: 87px;
                padding: 0 100px 0 100px;
                border-bottom: 1px solid #E5E5E5;
                display: flex;
                align-items: center;
                opacity: 0.8;
                font-weight: bold;

                p {
                    margin: 0;
                }
            }

            .content-warp {
                margin: 2vw 5vw 3vw 5vw;
                padding: 20px 25px 30px 25px;

                .msg-title {
                    font-size: 16px;
                    color: #666666;
                    line-height: 36px;
                    font-weight: bold;
                    text-align: center;
                }

                p {
                    font-size: 14px;
                    color: #666666;
                    line-height: 2;
                }
            }
        }

        @media (max-width: 900px) {
            .content-title {
                padding-left: 50px !important;
                height: 15vw !important;
            }
        }
    }


</style>
